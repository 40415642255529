.order-view {
    display: flex;
    justify-content: center;
}

.order-view-info {
    width: 35%;
}

.order-view-products {
    width: 65%;
}

.order-view h2 {
    font-size: 22px;
    font-weight: 200;
    margin-bottom: 20px;
}

.order-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
}

.order-edit-comment {
    margin-left: 10px;
    border-radius: 3px;
    padding: 2px;
    min-height: 100px;
}

.order-text {
    font-size: 14px;
    font-weight: 900;
}

.order-value {
    font-size: 14px;
    padding: 0 10px;
}

.order-product {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #F1F1F1;
    padding: 5px 0;
}

.order-product-name {
    font-weight: 400;
    font-size: 14px;
}

.order-product-amont-price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3px;
}

.order-product-bold {
    font-weight: 800;
    font-size: 14px;
}

.order-product-text {
    padding: 0 5px;
    font-size: 14px;
}

.order-product-total {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 100;
}





.order-status-box {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
}

.order-store-change {
    width: 40%;
}

.order-stores-list {
    width: 90%;
    max-height: 400px;
    background: #FF6B6B;
    box-shadow: 0 0 40px #e1e1e1;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    overflow: scroll
}

.order-store-empty {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 300;
    color: #444
}

.order-store-selected-name {
    font-size: 13px;
    font-weight: 800;
}

.order-store-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    margin: 0 10px;
    border-bottom: 1px solid #333;
    cursor: pointer;
    position: relative;
}

.order-store-item img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    opacity: 0.8;
}

.order-store-text {
    margin-left: 10px;
}

.order-store-name {
    font-size: 13px;
    font-weight: 800;
    color: white
}

.order-store-address {
    font-weight: 400;
    font-size: 12px;
    color: #999;
    margin-top: -3px;
}

.check {
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.check span {
    font-size: 10px;
    color: white
}





/* ORDER STATUS STYLES */
.order-status-change {
    width: 60%;
}

.order-status-box>h2 {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 0;
}

.order-status-box p {
    font-size: 14px;
    font-weight: 300;
    color: #666;
    margin-bottom: 20px;
}


.order-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}

.order-status-item {
    height: 40px;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #f8f9ff;
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 800;
    font-size: 13px;
    color: black
}

.order-status-item-active-blue {
    color: white;
    animation: pulse-blue 1s infinite;
    -webkit-animation: pulse-blue 1s infinite;
    background: #2196f3;
}

.order-status-item-active-red {
    color: white;
    animation: pulse-red 1s infinite;
    -webkit-animation: pulse-red 1s infinite;
    background: #f44336;
}

.order-status-item-active-yellow {
    color: white;
    animation: pulse-yellow 1s infinite;
    -webkit-animation: pulse-yellow 1s infinite;
    background: #ffc107;
}

.order-status-item-active-green {
    color: white;
    animation: pulse-green 1s infinite;
    -webkit-animation: pulse-green 1s infinite;
    background: #8bc34a;
}

.order-status-item-active-whiteblue {
    color: white;
    animation: pulse-whiteblue 1s infinite;
    -webkit-animation: pulse-whiteblue 1s infinite;
    background: #4fc3f7;
}


.order-status-item-active-darkgreen {
    color: white;
    animation: pulse-darkgreen 1s infinite;
    -webkit-animation: pulse-darkgreen 1s infinite;
    background: #4caf50;
}

.order-status-item-active-purple {
    color: white;
    animation: pulse-purple 1s infinite;
    -webkit-animation: pulse-purple 1s infinite;
    background: #3f51b5;
}

.order-status-item-active-darkblue {
    color: white;
    animation: pulse-darkblue 1s infinite;
    -webkit-animation: pulse-darkblue 1s infinite;
    background: #0D47A1;
}

.order-status-item-active-pink {
    color: white;
    animation: pulse-pink 1s infinite;
    -webkit-animation: pulse-pink 1s infinite;
    background: #e91e63;
}

.order-checkbox {
    width: 20px;
    height: 20px;
}

.inOneLine {
    display: flex;
    align-items: center;
}

.inOneLine .point {
    margin-top: 0px !important;
    margin-left: 10px;
    width: 15px !important;
    height: 15px !important;
    border-radius: 10px !important;
    cursor: pointer;
}

.nameWithLink {
    width: 30%;
    font-size: 12px;
}

.product .menu-mini .number {
    width: 3%;
}

.menu-text {
    font-size: 13px;
}

.menu-text.big {
    width: 45%;
}

.menu-text.small {
    width: 12%;
}

.menu-text.edit {
    width: 30%;
}







/* BLUE ANIMATION */
@-webkit-keyframes pulse-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 #2196f3;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-blue {
    0% {
        -moz-box-shadow: 0 0 0 0 #2196f3;
        box-shadow: 0 0 0 0 #2196f3;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}



/* RED ANIMATION */
@-webkit-keyframes pulse-red {
    0% {
        -webkit-box-shadow: 0 0 0 0 #f44336;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-red {
    0% {
        -moz-box-shadow: 0 0 0 0 #f44336;
        box-shadow: 0 0 0 0 #f44336;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}


/* YELLOW ANIMATION */
@-webkit-keyframes pulse-yellow {
    0% {
        -webkit-box-shadow: 0 0 0 0 #ffc107;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-yellow {
    0% {
        -moz-box-shadow: 0 0 0 0 #ffc107;
        box-shadow: 0 0 0 0 #ffc107;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}


/* GREEN ANIMATION */
@-webkit-keyframes pulse-green {
    0% {
        -webkit-box-shadow: 0 0 0 0 #8bc34a;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-green {
    0% {
        -moz-box-shadow: 0 0 0 0 #8bc34a;
        box-shadow: 0 0 0 0 #8bc34a;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}




/* WHITEBLUE ANIMATION */
@-webkit-keyframes pulse-whiteblue {
    0% {
        -webkit-box-shadow: 0 0 0 0 #4fc3f7;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-whiteblue {
    0% {
        -moz-box-shadow: 0 0 0 0 #4fc3f7;
        box-shadow: 0 0 0 0 #4fc3f7;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}






/* DARKGREEN ANIMATION */
@-webkit-keyframes pulse-darkgreen {
    0% {
        -webkit-box-shadow: 0 0 0 0 #4caf50;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-darkgreen {
    0% {
        -moz-box-shadow: 0 0 0 0 #4caf50;
        box-shadow: 0 0 0 0 #4caf50;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}





/* PURPLE ANIMATION */
@-webkit-keyframes pulse-purple {
    0% {
        -webkit-box-shadow: 0 0 0 0 #3f51b5;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-purple {
    0% {
        -moz-box-shadow: 0 0 0 0 #3f51b5;
        box-shadow: 0 0 0 0 #3f51b5;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}



/* PURPLE ANIMATION */
@-webkit-keyframes pulse-darkblue {
    0% {
        -webkit-box-shadow: 0 0 0 0 #0D47A1;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-darkblue {
    0% {
        -moz-box-shadow: 0 0 0 0 #0D47A1;
        box-shadow: 0 0 0 0 #0D47A1;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}





/* PURPLE ANIMATION */
@-webkit-keyframes pulse-pink {
    0% {
        -webkit-box-shadow: 0 0 0 0 #e91e63;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-pink {
    0% {
        -moz-box-shadow: 0 0 0 0 #e91e63;
        box-shadow: 0 0 0 0 #e91e63;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}