.layout {
    display: block;
}

.layout-inner {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right top, #ffffff, #fafbfc, #f3f7f7, #eef3f1, #eceee9);
}

.container {
    width: calc(100% - 225px);
    min-height: 100vh;
    position: relative;
}


.content {
    padding: 0;
}


/* Content TOP Styles */
.content-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    height: 70px;
    position: relative;
}

.content-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content-name img {
    width: 25px;
    height: 25px;
    opacity: 0.7;
}

.content-name p {
    font-size: 24px;
    font-weight: 900;
    padding-left: 10px;
}

.content-name.stat p {
    font-size: 15px;
}

.content-top.stat select {
    margin-left: 10px;
    height: 40px;
    border-radius: 10px;
    padding: 0px 5px;
    position: relative;
}

.content-top.stat select::after {
    content: "▼";
    font-size: 1rem;
    top: 6px;
    right: 10px;
    position: absolute;
}

.content-name span {
    display: block;
    padding: 4px 8px;
    background-color: var(--primary);
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 7px;
    color: white;
    border-radius: 20px;
    font-weight: 1000;
}

.content-name span.hide {
    opacity: 0;
}

.content-create {
    margin-top: 5px;
    margin-left: 30px;
}

.content-create.blue {
    margin-left: 15px;
}

.content-create-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    height: 40px;
    width: 100px;
    border-radius: 20px;
    color: white;
    font-size: 13px;
    cursor: pointer;
    font-weight: 1000;
    transition: transform 0.5s;
}

.content-create-button:hover {
    transform: scale(1.05);
}

.content-create-button.green {
    width: 150px;
    background-color: var(--primary);
}

.content-create-button.blue {
    background-color: rgb(75, 166, 222);
}

.content-search {
    margin-top: 5px;
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.content-search img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    position: absolute;
    left: 16px;
    opacity: 0.5;
}

.content-search input {
    width: 240px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0 0 20px #f5f5f5;
    border: none;
    outline: none;
    padding-left: 36px;
    padding-top: 2px;
    padding-right: 10px;
    background: white;
}







/* Content box styles */
.content-box {
    background: white;
    border-radius: 20px;
    box-shadow: 0 0 20px #f5f5f5;
    padding: 20px;
    margin: 0 20px;
}

.content-box.products {
    width: 96%;
    overflow: scroll;
}

.content-box.products .content-box-head {
    width: 1600px;
}

.content-box.products .content-data-box {
    width: 1600px;
}

.content-data-box {
    min-height: 400px
}

.orders-with-scroll {
    /* height: 600px;
    overflow: scroll; */
}

.content-box-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}

.content-box-head.products .name {
    width: 29%;
}

.content-head {
    width: 20%;
    padding: 4px;
    font-weight: 1000;
    font-size: 13px;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-head.filter-button:hover {
    background-color:#3f51b5;
    color: white;
    cursor: pointer;
}

.content-box-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}

.content-box-body.admin {
    background-color: yellow;
}

.content-head.order {
    width: 26% !important;
}

.content-head.order {
    width: 26% !important;
}

.content-head.order-stat {
    width: 30% !important;
}

.content-box-body.red {
    background-color: rgba(255, 0, 0, 0.499);
}

.content-box-body.green {
    background-color: rgba(0, 255, 68, 0.439);
}

.content-box-body.yellow {
    background-color: rgba(166, 255, 0, 0.439);
}
 
.content-item {
    width: 20%;
    padding: 4px;
    font-weight: 300;
    font-size: 13px;
    min-height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    overflow: scroll;
}

.content-item.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-box-body.products .content-item {
    border-right: 1px solid #f1f1f1;
    font-weight: 500;
    font-size: 13px;
}

.content-item img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.content-item img.ava {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.number {
    width: 5%;
}

.name {
    width: 30%;
}

.content-box.report {
    width: 96%;
    overflow: scroll;
}

.content-box.report .content-box-head {
    width: 1500px;
}

.content-box.report .content-data-box {
    width: 1500px;
}

.content-box-head.report .name {
    width: 32%;
}


.content-head.products {
    width: 28%;
}

.content-layout-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.content-layout-content p {
    font-size: 14px;
    margin: 5px 0
}

/* Specify style for ORDER page */
.content-data-box.order .content-item,
.content-box-head.order .content-head {
    width: 16.6%;
}

.content-data-box.order .content-item.number,
.content-box-head.order .content-head.number {
    width: 6.6%;
}

.content-data-box.order .content-item.name,
.content-box-head.order .content-head.name {
    width: 26.6%;
}

.content-data-box.order .content-item.blue {
    font-weight: 900;
    color: #2196f3;
    cursor: pointer;
}


/* Content Element styles */
.content-element {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-element span {
    font-size: 30px;
}





/* 
    CONTENT BUTTON STYLES 
*/
.content-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-buttons.absolute {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
}

.content-item-button {
    background: black;
    color: white;
    margin-left: 10px;
    padding: 8px 16px;
    font-weight: 1000;
    font-size: 12px;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.5s;
    text-decoration: none;
}

.content-item-button:hover {
    transform: scale(1.05);
}

.content-item-button.delete {
    background-color: #f44336;

}

.content-item-button.remove {
    background-color: #f4be36;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
    text-align: center;
}

.content-item-button.green {
    background-color: var(--primary);
}

.content-item-button.blue {
    background-color: rgba(0, 55, 255, 0.784);
}




/* Tag styles */
.tag {
    background: black;
    color: white;
    font-size: 12px;
    font-weight: 1000;
    padding: 4px 10px;
    border-radius: 20px;
    text-align: center;
}

.tag.green {
    background: #8bc34a;
}

.tag.yellow {
    background: #ffc107;
}

.tag.whiteblue {
    background: #4fc3f7;
}

.tag.blue {
    background: #2196f3;
}

.tag.darkgreen {
    background: #4caf50;
}

.tag.purple {
    background: #3f51b5;
}

.tag.darkblue {
    background: #0D47A1;
}

.tag.red {
    background: #f44336;
}

.tag.pink {
    background: #e91e63;
}







/* CONTENT FORM STYLES */
.content-form-box {
    display: flex;
    justify-content: center;
}

.content-form-box.partner-stat .select-menu {
    margin-bottom: 0px;
}

.content-form-half {
    width: 50%;
    padding-right: 30px;
}

.content-form-half.full {
    width: 100%;
}

.content-form-half h2 {
    font-weight: 300;
    font-size: 22px;
    margin-bottom: 20px;
}

.content-form-half p {
    font-size: 14px;
    font-weight: 800;
    padding-bottom: 3px;
}


.content-form-half input[type="text"], .content-form-half input[type="number"] {
    width: 100%;
    height: 44px;
    padding: 10px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    outline: none;
    margin-bottom: 15px;
}

.content-space {
    height: 10px;
}

.content-input-file {
    position: relative;
    display: inline-block;
    outline: none;
    margin-top: 3px;
}

.content-input-file-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 13px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 6px;
    background-color: #FF6B6B;
    height: 40px;
    padding: 10px 20px;
    line-height: 22px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: transform 0.5s;
    font-weight: 800;
}

.content-input-file-text {
    padding: 0 10px;
    line-height: 40px;
    display: inline-block;
    font-size: 12px;
    color: #666;
}

.content-input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

/* Focus */
.content-input-file input[type=file]:focus+.content-input-file-button {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover/active */
.content-input-file:hover .content-input-file-button {
    transform: scale(1.05);
}

/* Disabled */
.content-input-file input[type=file]:disabled+.content-input-file-button {
    background-color: #eee;
}



.content-form-half select {
    width: 100%;
    height: 44px;
    padding: 10px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    outline: none;
    margin-bottom: 15px;
}

.content-form-half textarea {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    outline: none;
    margin-bottom: 15px;
}

.select-menu {
    position: relative;
    width: 100%;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    outline: none;
    margin-bottom: 5px;
    min-height: 50px;
}

.select-menu .items-list {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.select-menu .items-list .items-list-inner {
    width: 95%;
    height: 100%;
    object-fit: contain;
}

.select-menu .items-list .items-list-inner.multiple {
    width: 95%;
    height: 100%;
    object-fit: contain;
    display: flex;
    flex-wrap: wrap;
}

.items-list-inner div {
    padding: 3px 5px;
    background-color: #0000000f;
    margin: 1px;
    border-radius: 3px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.items-list-inner .multiple {
    padding: 1px;
}

.items-list-inner div .close {
    /* right: 5px !important; */
    /* top: 0px !important; */
    width: 17px !important;
    height: 17px !important;
    cursor: pointer;
    margin-top: 0px !important;
    margin-left: 5px;
}

.product .input-values {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.product.menu-mini .input-values {
    width: 20%;
}

.product.menu-mini.edit .input-values {
    width: 30%;
}

.product.menu-mini .input-values.header {
    width: 20% !important;
}

.product.menu-mini.edit .input-values.header {
    width: 30% !important;
}


.product.header {
    font-size: 14px;
}

.product .input-values.full {
    width: 100%;
}

.product .input-values.full p {
    font-size: 12px;
    font-weight: 800;
    padding-bottom: 3px;
    width: 14.3%;
    text-align: center;
    padding: 0px 2px;
    border-right: 0.5px solid #00000078;
}

.product .input-values.full p.special {
    width: 100%;
    padding: 10px;
    border: none;
    font-size: 10.5px;
    overflow: scroll;
    word-wrap: break-word;
}

.product .input-values.header {
    padding-right: 3%;
}

.product .input-values.full.header {
    padding-right: 2%;
}

.items-list-inner div .quantity {
    width: 50%;
    margin-bottom: 0px;
    padding: 5px;
    height: 30px;
    border-radius: 5px;
}

.items-list-inner div .price {
    /* width: 50%; */
    margin-bottom: 0px;
    padding: 5px;
    height: 30px;
    border-radius: 5px;
}

/* .items-list-inner div input[type="text"].price:after {
    padding-right: 20px;
    content: "₸";
    position: absolute;
    top: 0;
    right: 5px;
    line-height: 30px; 
} */

.select-menu .items-list .img-block {
    padding: 12px 17px;
}


.select-menu .items-list img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-top: 6px;
}

.select-dropdown {
    position: relative;
    width: 1200px;
    margin-top: 1px;
    background-color: ghostwhite;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    min-height: 400px;
    cursor: pointer;
    overflow: hidden;
}

.select-dropdown.multi {
    position: relative;
    width: 1200px;
    margin-top: 1px;
    background-color: ghostwhite;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    height: 570px;
    cursor: pointer;
    overflow: scroll;
}

.select-dropdown.mono {
    position: absolute;
    width: 100%;
    min-height: none;
    z-index: 3;
    min-height: auto;
}

.select-dropdown.mono .search-input {
    width: 98% !important;
}

.select-dropdown.mono .select-item {
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
}

.select-dropdown.mono .select-item p {
    width: auto;
    border-right:none;
}

.select-dropdown.roles {
    display: flex;
    flex-wrap: wrap;
}



.select-dropdown.roles .select-item p {
    font-size: 15px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    border-right: none;
    padding: 10px !important;

}

.inputs {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 7px;
}

.select-dropdown .select-item {
    padding: 0px 10px;
    border-top: 0.25px solid rgba(0, 0, 0, 0.355);
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.355);
    display: flex;
    align-items: center;
}

.select-item.head {
    background-color: white;
}

.select-item.active {
    border: 2px solid blue;
}

.select-item.disabled {
    background-color: rgba(255, 0, 0, 0.317) !important;
    opacity: 0.5;
}

.select-item.added {
    background-color: #00ff0982 !important;
}

.select-dropdown .select-item p {
    font-size: 12px;
    font-weight: 500;
    width: 20%;
    padding: 4px 0px;
    padding-left: 5px;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #d4d4d9;
}

.select-dropdown .select-item img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    margin-left: 50px;
}

.select-dropdown.roles .select-item {
    background-color: white;
    margin: 2px 5px; 
    border-radius: 5px;
    border: 0.5px solid black;
}

.select-dropdown.roles .select-item p {
    width: auto;
}

.select-dropdown.roles .select-item img {
    margin-left: 0px;
}

.select-dropdown .search-input {
    width: 47.5% !important;
    margin: 4px 0px;
    padding: 0px 8px;
    height: 32px !important;
    margin-bottom: 7px !important;
}

img.hidden {
    opacity: 0;
}

.withScroll {
    overflow: scroll;
    font-size: 14px !important;
    word-wrap: break-word;
    padding-right: 3px !important;
}

.withWrapWord {
    overflow: scroll;
    font-size: 12px !important;
    word-wrap: break-word;
    padding-right: 3px !important;
}

.similar-height input {
    /* height: 100px; */
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background-color: #2196f3;
}

.clickable.green {
    background-color: #28db58;
}

.clickable.red {
    background-color: #de5634ab;
}


/* Select */
.items-list .partner {
    width: 200px;
    font-size: 14px;
    padding: 14px;
}


/* Statistics Select */

.select-menu.product {
    position: relative;
    margin-bottom: 0px;
    min-height: 40px;
    margin-left: 20px;
}

.select-menu.product .items-list .items-list-inner .product {
    margin: 3px;
    border-radius: 6px;
    padding: 4px;
}

.select-menu.product .items-list .items-list-inner .product .input-values {
    margin: 0px;
}

.select-menu.product .items-list .img-block {
    padding: 6px 12px;
}

.input-values .quantity {
    width: 70% !important;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}





.content-form-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-form-button {
    height: 50px;
    width: 170px;
    margin-top: 30px;
    background: #FF6B6B;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 1000;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 0.5s;
    font-size: 14px;
    box-shadow: 0 0 40px #D9D9D9;
    border: none;
    outline: none;
}

.content-form-button:hover {
    transform: scale(1.05);
}


.content-form-button.simple {
    margin-top: 0px;
    height: 40px;
}






/* CONTENT GALLERY */
.content-gallery-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}

.content-gallery-file {
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
}

.content-gallery-button {
    border: 2px dashed #666;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content-gallery-button img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}


.content-gallery-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

.content-gallery-item {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    margin-right: 10px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}

.content-gallery-item img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
}

.content-gallery-remove {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    box-shadow: 0 0 15px #999;
    background: #f44336;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content-gallery-remove img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}





/* Characteristics */
.characteristics {
    margin-bottom: 30px;
}

.content-empty-characteristics {
    font-size: 12px;
    color: #666
}

.content-characteristics-button {
    height: 34px;
    width: 100px;
    background: black;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    transition: transform 0.5s;
    font-size: 13px;
    margin-top: 10px;
}

.content-characteristics-button:hover {
    transform: scale(1.05);
}


.swal2-content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.swal2-content-select {
    width: 255px;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .06), 0 0 0 3px rgba(0, 0, 0, 0);
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
}

.swal2-content-select-label {
    font-size: 14px;
    font-weight: 700;
    width: 255px;
    display: flex;
    padding-bottom: 3px;
}


.content-characteristics-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2px 0
}

.content-characteristics-item-name {
    font-size: 14px;
    font-weight: 800;
}

.content-characteristics-item-value {
    font-size: 14px;
    margin-left: 40px;
}

.content-characteristics-item-remove {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #f44336;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;
}

.content-characteristics-item-remove img {
    width: 15px;
    height: 15px;
    object-fit: contain;
}




.content-item-review {
    display: flex;
    flex-direction: column;
}

.content-item-review p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    margin: 2px 0
}

.content-item-review p b {
    margin-right: 6px;
}

.review-rate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 800;
}

.review-rate img {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    margin-top: -2px;
}



.switch {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
}

.switch p {
    width: 200px;
}

.swal-error {
    color: #ffffff;
    padding-top: 10px;
    font-weight: bold;
}

.space {
    margin-left: 10px;
}

.space-bottom {
    margin-bottom: 40px;
}

.react-datepicker__input-container input {
    background-color: white;
    padding: 5px;
    border: 1px solid;
    border-radius: 5px;
    margin: 10px 0px;
    margin-left: 5px;
    height: 25px;
    margin-top: 0px;
    width: 85px;
}



.clear-button {
    position: absolute;
    align-self: center;
    bottom: 2px;
    margin-left: 20px;
    color: red;
    cursor: pointer;
}


.statTime .react-datepicker__input-container input {
    margin: 0px;
    margin-right: 10px;
}

.clear-button.stat {
    right: inherit;
    margin-left: 20px;
}

.veryStrong {
    font-weight: 800;
}
