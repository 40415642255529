/* ----------- */
/* Menu Styles */
/* ----------- */
.menu {
    width: 225px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    /* overflow: scroll;
    height: 100vh;
    padding-bottom: 200px; */
}


/* Menu Logo Styles */
.menu-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.menu-logo img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.menu-logo h2 {
    font-weight: 300;
    margin-left: 10px;
    font-size: 18px;
    letter-spacing: 4px;
}



/* Menu Item Styles */
.menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 0.3px solid #00000029;
}

.menu-dot {
    background: transparent;
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 12px;
    margin-left: 5px;
    margin-top: 2px;
}

.menu-item.active .menu-dot {
    background: var(--primary);
    box-shadow: 0 0 10px var(--primary);
}

.menu-item img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    opacity: 0.7;
}

.menu-item img.bigger {
    width: 24px;
    height: 24px;
    margin-left: -2px;
}

.menu-item.active img {
    opacity: 1;
}

.menu-name {
    color: #4d4e55;
    margin-top: 2px;
    font-weight: 500;
    margin-left: 8px;
    font-size: 15px;
}

.menu-item.active .menu-name {
    color: #000;
    font-weight: 700;
}

.sidemenu {
    max-height: 0px;
    -webkit-transition: max-height 0.5s; 
    -moz-transition: max-height 0.5s; 
    -ms-transition: max-height 0.5s; 
    -o-transition: max-height 0.5s; 
    transition: max-height 0.5s, padding-bottom 0.8s; 
    background: #e6e6e69e;
    overflow: hidden;
    padding-bottom: 0px;
}

.sidemenu.active {
    max-height: 600px;
}

/* Submenu Item Styles */
.submenu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
}

.submenu-dot {
    background: transparent;
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 12px;
    margin-left: 5px;
    margin-top: 2px;
}

.submenu-item.active .submenu-dot {
    background: var(--primary);
    box-shadow: 0 0 10px var(--primary);
}

.submenu-item img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    opacity: 0.7;
}

.submenu-item img.bigger {
    width: 24px;
    height: 24px;
    margin-left: -2px;
}

.submenu-item.active img {
    opacity: 1;
}

.submenu-name {
    color: #4d4e55;
    margin-top: 2px;
    font-weight: 500;
    margin-left: 8px;
    font-size: 13px;
}

.submenu-item.active .submenu-name {
    color: #000;
    font-weight: 700;
}

.sub_sidemenu {
    max-height: 0px;
    -webkit-transition: max-height 0.5s; 
    -moz-transition: max-height 0.5s; 
    -ms-transition: max-height 0.5s; 
    -o-transition: max-height 0.5s; 
    transition: max-height 0.5s, padding-bottom 0.8s; 
    background: #ffffff;
    overflow: hidden;
    padding-bottom: 0px;
}

.sub_sidemenu.active {
    max-height: 600px;
    padding-bottom: 5px;
}



.menu-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 8px 8px 36px;
    background-color: #e6e6e6;
    border-bottom: 0.5px solid #9b9b9b6e;
}

.menu-space.mt10 {
    margin-top: 10px;
}

.menu-space p {
    font-size: 13px;
    font-weight: 700;
    color: #000000;
}

/* Triangle */
.triangle {
    display: inline-block;
    width: 13px !important;
    height: 13px !important;
    margin-right: 10px;
    margin-bottom: -2px;
}

.triangle.mini {
    position: absolute;
    right: 12px;
    width: 11px !important;
    height: 11px !important;
}

.triangle-top {
    transform: rotate(0deg);
    transition: transform 0.5s;  
}

.triangle-bottom {
    transform: rotate(-180deg);
    transition: transform 0.5s; 
}
