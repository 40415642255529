.nav-area {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    height: 58px;
}

.logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
}

.menus {
    display: flex;
    list-style: none;
    padding: 0px 20px;
}

.menu-items {
    position: relative;
    font-size: 14px;
}

.menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.menu-items button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;

    /* new additions */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-items a,
.menu-items .directed,
.menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
    display: flex;
}

/* new addition */
.menu-items button a {
    padding: 0;
}

.menu-items a:hover,
.menu-items button:hover {
    background-color: #f2f2f2;
}

.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.arrow-right::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
    transform: rotate(-90deg);
}

.dropdown {
    position: absolute;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
}

.dropdown.show {
    display: block;
}

.dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}

/* new addition */

.arrow.custom {
    display: none;
}

@media (min-width: 960px) {
    .arrow.custom {
        display: block;
    }
}

.nav_image {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.border-b1 {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.447) !important;
}