

/* Layout Header Styles */
.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    position: absolute;
    right: 0;
    margin-top: 10px;
}

.header-user {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
}

.header-user img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.header-name {
    padding-left: 7px;
}

.header-name p {
    font-weight: 1000;
    font-size: 14px;
}

.header-name span {
    display: block;
    font-weight: 200;
    font-size: 12px;
    margin-top: -3px;
}

.header-notification {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-notification img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.statTime {
    display: flex;
    display: flex;
    margin-left: 40px;
    align-items: center;
}
