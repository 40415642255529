:root {
    --primary: #4caf50;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
}

html,
body,
#root,
.app {
    height: 100vh;
    /* overflow: hidden; */
}

::-webkit-scrollbar {
    display: none;
    height: 0px;
    width: 30px;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar,
#root::-webkit-scrollbar,
div::-webkit-scrollbar {
    display: none;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

a {
    text-decoration: none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}