.dashboard-welcome {
    width: 40%;
    background: white;
    border-radius: 20px;
    box-shadow: 0 0 20px #f5f5f5;
    padding: 20px;
    margin: 0 20px;
}

.dashboard-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-user button {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background-color: red;
    color: #f5f5f5;
    border: none;
}

.dashboard-user button:active {
    background-color: rgba(255, 0, 0, 0.637);
    border-width: 0;
}

.dashboard-welcome h2 {
    font-size: 20px;
    font-weight: 200;   
}

.dashboard-welcome h2 b {
    font-weight: 800;
}

.dashboard-welcome p {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #999;
}